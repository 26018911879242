import React from "react";
import Hero from "../components/Hero";
import Services from "../components/Services";
import Portfolio from "../components/Portfolio";
import ClutchWidget from "../components/ClutchWidget";
const Home = () => {
  return (
    <div>
      <Hero />
      <Services />
      <Portfolio />
<ClutchWidget />
    </div>
  );
};

export default Home;
