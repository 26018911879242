import { motion } from "framer-motion";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export const slideUp = (delay) => ({
  hidden: {
    opacity: 0,
    y: 20,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.2,
      delay: delay,
      ease: "easeInOut",
    },
  },
});

const getTextAndColor = (pathname) => {
  switch (pathname) {
    case "/animation":
      return {
        text: "3D & Animation",
        color: "text-indigo-600", // A vibrant indigo color for creativity
        title: "Immersive 3D & Animation Solutions",
        detail:
          "Bring your ideas to life with cutting-edge 3D modeling and animation. From captivating visuals to intricate designs, we empower software companies and creatives to deliver unparalleled experiences.",
      };

    case "/webdev":
      return {
        text: "Software & Web",
        color: "text-yellow-500",
        title: "Web, Mobile & Software Solutions",
        detail:
          "Building cutting-edge software solutions to accelerate your business. We specialize in scalable web applications, intuitive user experiences, and custom development to bring your ideas to life.",
      };

    case "/graphics":
      return {
        text: "Graphics & Video Editing",
        color: "text-blue-500",
        title: "Graphics & Video Editing Services for Your Brand",
        detail:
          "Our expert team delivers professional graphics and video editing services tailored to your brand's unique needs. From creating stunning visuals and logos to producing engaging video content, we help businesses stand out and connect with their audience effectively.",
      };
    default:
      return { text: "Welcome", color: "text-white" };
  }
};

const CoverVideo = ({ vidSource, onLoadedData }) => {
  const location = useLocation();
  const { text, color, detail, title } = getTextAndColor(location.pathname);

  return (
    <section className="w-full h-screen relative flex flex-col md:flex-row-reverse font-wittgenstein">
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-60" />

      {/* Animated Text and Buttons Container */}
      <div className="absolute inset-0 flex flex-col md:flex-row-reverse items-start justify-center w-full h-full p-4 md:p-16 lg:py-56">
        {/* "Lael" Animated Text on Right Side */}
        <div className="flex flex-col items-start md:items-center md:w-1/2 mb-20 md:mb-0">
          <div className="flex flex-row font-kaushan mb-3">
            {["L", "a", "e", "l"].map((letter, index) => (
              <motion.h1
                key={letter}
                variants={slideUp(0.5 + index * 0.1)}
                initial="hidden"
                whileInView="show"
                className="font-kaushan text-6xl sm:text-7xl md:text-8xl lg:text-9xl shadow-lg text-customTeal-600"
              >
                {letter}
              </motion.h1>
            ))}
          </div>
          <motion.div
            variants={slideUp(1)}
            initial="hidden"
            whileInView="show"
            className={`text-3xl sm:text-4xl md:text-5xl font-bold ${color} drop-shadow-[2px_2px_10px_rgba(0,0,0,0.3)]`}
            style={{
              textShadow:
                "0 3px 10px rgba(0,0,0,0.6), 0 6px 20px rgba(0,0,0,0.3)",
            }}
          >
            {text}
          </motion.div>

          <motion.h2
            variants={slideUp(1.1)}
            initial="hidden"
            whileInView="show"
            className="font-special_elite text-lg sm:text-xl md:text-2xl font-light capitalize mt-3 text-white"
          >
            Inspire. Create. Believe
          </motion.h2>
        </div>

        {/* Content Section (Text and Buttons) on Left Side */}
        <div className="flex flex-col justify-center items-start w-full md:w-1/2">
          <motion.h1
            variants={slideUp(0.5)}
            initial="hidden"
            whileInView="show"
            className="text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-extrabold text-white mb-3"
          >
            {title}
          </motion.h1>
          <motion.p
            variants={slideUp(0.7)}
            initial="hidden"
            whileInView="show"
            className="max-w-xl mb-4 text-sm sm:text-base md:text-lg font-light text-gray-300"
          >
            {detail}
          </motion.p>
          <motion.div
            variants={slideUp(0.8)}
            initial="hidden"
            whileInView="show"
            className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4"
          >
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-5 py-3 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 transform transition-transform duration-200 hover:translate-x-1 z-10"
            >
              Contact Us
              <svg
                className={`w-4 h-4 ml-2 -mr-1 transition-transform duration-200 group-hover:translate-x-1 group-hover:text-${color}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </motion.div>
        </div>
      </div>

      {/* Background Video */}
      <video
        className="w-full h-screen object-cover"
        src={vidSource}
        type="video/mp4"
        autoPlay
        muted
        loop
        playsInline
        preload="auto"
        controls={false}
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
        onLoadedData={onLoadedData}
      />
    </section>
  );
};

export default CoverVideo;
