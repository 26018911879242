import React, { useState, useRef, useEffect } from "react";
import { Facebook, Twitter, Instagram, Linkedin, Mail } from "lucide-react";
import { useDarkMode } from "../Context/DarkModeContext"; // Import the dark mode context

export default function Footer() {
  const { isLightMode } = useDarkMode();
  const [isDropdownOpen, setDropdownOpen] = useState(false); // State for dropdown visibility
  const dropdownRef = useRef(null); 

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <footer
      className={`py-16 ${
        isLightMode
          ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
          : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Top Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {/* About Section */}
          <div>
            <h3 className={`text-2xl font-bold mb-4 text-customTeal-600`}>
              About Us
            </h3>
            <p
              className={`font-bold ${
                isLightMode ? "text-gray-600" : "text-gray-400"
              }`}
            >
              Bringing your vision to life with expert web and mobile
              development, captivating designs, immersive 3D animations, and
              impactful branding solutions. Let's create something extraordinary
              together!
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className={`text-2xl font-bold mb-4 text-customTeal-600 z-5`}>
              Quick Links
            </h3>
            <ul className="space-y-3">
              {[
                { name: "Home", href: "/" },
                { name: "About", href: "/about" },
                { name: "Contact Us", href: "/contact" },
                {
                  name: "Categories",
                  href: "/#portfolio",
                  dropdown: [
                    { name: "Animation", href: "/animation" },
                    { name: "Graphics", href: "/graphics" },
                    { name: "Web Development", href: "/webdev" },
                  ],
                },
              ].map(({ name, href, dropdown }, index) => (
                <li
                  key={index}
                  className="relative duration-200 hover:translate-x-1 z-10"
                >
                  <a
                    href={href}
                    onClick={
                      dropdown
                        ? (e) => {
                            e.preventDefault();
                            setDropdownOpen(!isDropdownOpen);
                          }
                        : null
                    } // Toggle dropdown on click
                    className={`hover:text-customTeal-500 font-bold left-full z-10 ${
                      isLightMode
                        ? "text-gray-600 hover:text-teal-600 transition"
                        : "text-gray-400 hover:text-customTeal-500 transition"
                    }`}
                  >
                    {name}
                  </a>
                  {/* Dropdown Menu */}
                  {dropdown &&
                    isDropdownOpen && ( // Show dropdown if open
                      <ul
                        ref={dropdownRef} // Attach ref to the dropdown
                        className={`absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md transition-all duration-200 ${
                          isLightMode
                            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
                            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
                        }`}
                      >
                        {dropdown.map((item, idx) => (
                          <li key={idx} className="hover:bg-customTeal-500">
                            <a
                              href={item.href}
                              className={`block px-4 py-2 text-sm ${
                                isLightMode
                                  ? "hover:text-white"
                                  : "hover:text-gray-900"
                              }`}
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Section */}
          <div>
            <h3 className={`text-2xl font-bold mb-4 text-customTeal-600`}>
              Get in Touch
            </h3>
            <p
              className={`font-bold mb-4 ${
                isLightMode ? "text-gray-600" : "text-gray-400"
              }`}
            >
              We'd love to hear from you! Reach out to us for any inquiries or
              collaborations.
            </p>
            <a
              href="mailto:info@yourwebsite.com"
              className={`inline-flex items-center ${
                isLightMode
                  ? "text-gray-600 hover:text-teal-600 transition"
                  : "text-gray-200 hover:text-customTeal-500 transition"
              }`}
            >
              <Mail className="mr-2 h-5 w-5 text-customTeal-600 font-bold" />
              laelstudioeth@gmail.com{" "}
            </a>
          </div>
        </div>

        {/* Divider */}
        <div
          className={`border-t ${
            isLightMode ? "border-gray-300" : "border-gray-700"
          } my-12`}
        ></div>

        {/* Bottom Section */}
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Social Icons */}
          <div className="flex space-x-4">
            {[
              {
                Icon: Facebook,
                url: "https://web.facebook.com/profile.php?id=61569715943685",
              },
              { Icon: Twitter, url: "https://x.com/lael_studio" },
              {
                Icon: Instagram,
                url: "https://www.instagram.com/lael_eth/profilecard/?igsh=MTc5azdyMGZ5a3VpZA==",
              },
              {
                Icon: Linkedin,
                url: "https://www.linkedin.com/in/lael-studio-eth",
              },
            ].map(({ Icon, url }, index) => {
              return (
                <a
                  key={index}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${
                    isLightMode
                      ? "text-gray-600 hover:text-teal-600"
                      : "text-gray-400 hover:text-customTeal-500"
                  } transition`}
                  aria-label={`Visit our ${Icon.name} page`}
                >
                  <Icon className="h-6 w-6" />
                </a>
              );
            })}
            {/* {[Facebook, Twitter, Instagram, Linkedin].map((Icon, index) => (
              <a
                key={index}
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  isLightMode
                    ? "text-gray-600 hover:text-teal-600"
                    : "text-gray-400 hover:text-customTeal-500"
                } transition`}
                aria-label={`Visit our ${Icon.name} page`}
              >
                <Icon className="h-6 w-6" />
              </a>
            ))} */}
          </div>

          {/* Copyright Text */}
          <p
            className={`text-sm ${
              isLightMode ? "text-gray-600" : "text-gray-400"
            }`}
          >
            © {new Date().getFullYear()} Your Company. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
