import React from "react";
import { motion } from "framer-motion";

const pathVariant = {
  hidden: { opacity: 0, pathLength: 0 },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { duration: 2, ease: "easeInOut" },
  },
};

const fillVariant = {
  hidden: { fillOpacity: 0 },
  visible: {
    fillOpacity: 1,
    transition: { delay: 2, duration: 1 },
  },
};

const FilledAnimatedLogo = () => {
  return (
    <div className="flex items-center h-full">
      <motion.svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        initial="hidden"
        animate="visible"
        className="w-16 h-auto"
      >
        <motion.polygon
          points="500 500 0 500 22.45 455.1 44.9 410.19 67.36 365.29 250 0 276.77 53.54 183.78 240.29 121.28 365.29 98.83 410.19 76.38 455.1 423.62 455.1 401.17 410.19 153.08 410.19 175.44 365.29 432.64 365.29 455.1 410.19 477.55 455.1 500 500"
          fill="none"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinejoin="round"
          strokeLinecap="round"
          variants={pathVariant}
        />
        <motion.path
          d="M303.88,107.36l-106.32,213.03h212.64l-106.32-213.03ZM303.88,207.95l33.7,67.52h-67.4l33.7-67.52Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="6"
          strokeLinejoin="round"
          strokeLinecap="round"
          variants={pathVariant}
        />
        {/* Fill elements for both shapes */}
        <motion.polygon
          points="500 500 0 500 22.45 455.1 44.9 410.19 67.36 365.29 250 0 276.77 53.54 183.78 240.29 121.28 365.29 98.83 410.19 76.38 455.1 423.62 455.1 401.17 410.19 153.08 410.19 175.44 365.29 432.64 365.29 455.1 410.19 477.55 455.1 500 500"
          fill="currentColor"
          stroke="none"
          variants={fillVariant}
        />
        <motion.path
          d="M303.88,107.36l-106.32,213.03h212.64l-106.32-213.03ZM303.88,207.95l33.7,67.52h-67.4l33.7-67.52Z"
          fill="currentColor"
          stroke="none"
          variants={fillVariant}
        />
      </motion.svg>
    </div>
  );
};

export default FilledAnimatedLogo;
