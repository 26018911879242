import React from 'react';

const ClutchWidget = () => {
    return (
        <div>
            <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script>
            <div className="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="4" data-height="auto" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="2466640"></div>
        </div>
    );
};

export default ClutchWidget;
