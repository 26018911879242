import React, { useState } from "react";
import { ArrowRight, Code, Palette, Box } from "lucide-react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useDarkMode } from "../Context/DarkModeContext";
import mobileBg from '../assets/bg_mobile.mp4'
import desktopBg from '../assets/bg_desktop.mp4'

export const slideUp = (delay) => ({
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: { duration: 1.2, delay: delay, ease: "easeInOut" },
  },
});

export default function Hero() {
  const [isTransformed, setIsTransformed] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const { isLightMode } = useDarkMode();

  const handleGetStarted = () => {
    setIsTransformed(true);
  };

  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };

  const cardContainerVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.95 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { delay: 1 * i, duration: 4, ease: "anticipate" },
    }),
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.6, ease: "easeInOut" },
    },
  };

  return (
    <motion.div
      id="home"
      className={`relative h-screen flex flex-col items-center justify-between overflow-hidden ${isVideoLoading ? 'bg-black' : ''}`}
    >
      {/* Video Background */}
      {isVideoLoading && (
        <div className="absolute inset-0 flex items-center justify-center z-10">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[var(--primary-color)]"></div>
          </div>
      )}
      <video
        autoPlay
        muted
        loop
        playsInline
        onLoadedData={handleVideoLoad}
        className={`absolute w-full h-full object-cover hidden md:block transition-opacity duration-500 ${isVideoLoading ? 'opacity-0' : 'opacity-100'}`}
        style={{ zIndex: -1 }}
      >
        <source src={desktopBg} type="video/mp4" />
      </video>
      <video
        autoPlay
        muted
        loop
        playsInline
        onLoadedData={handleVideoLoad}
        className={`absolute w-full h-full object-cover md:hidden transition-opacity duration-500 ${isVideoLoading ? 'opacity-0' : 'opacity-100'}`}
        style={{ zIndex: -1 }}
      >
        <source src={mobileBg} type="video/mp4" />
      </video>
      
      {/* Overlay to ensure text readability */}
      <div 
        className="absolute inset-0"
        style={{ 
          backgroundColor: isLightMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.5)',
          zIndex: -1 
        }}
      />

      <div className="relative mt-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-14 lg:py-32 flex flex-col lg:flex-row items-center justify-between gap-10">
        {/* "Lael" Section */}
        <AnimatePresence>
          {!isTransformed && (
            <motion.div
              className="flex flex-col items-center justify-center lg:items-center lg:w-1/2 mb-1 lg:mb-0 order-first lg:order-last"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className="flex mt-10 font-kaushan text-9xl justify-center lg:justify-end mb-3">
                {["L", "a", "e", "l"].map((letter, index) => (
                  <motion.h1
                    key={letter}
                    variants={slideUp(0.5 + index * 0.1)}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className={`text-6xl md:text-8xl lg:text-10xl text-customTeal-600`}
                    style={{
                      textShadow: "2px 2px 4px black",
                    }}
                  >
                    {letter}
                  </motion.h1>
                ))}
              </div>
              <motion.h2
                variants={slideUp(1)}
                initial="hidden"
                animate="show"
                exit="hidden"
                className={`font-special_elite text-lg sm:text-xl md:text-2xl font-light capitalize mt-3 text-center ${
                  isLightMode ? "text-gray-600" : "text-white"
                }`}
              >
                Inspire. Create. Believe
              </motion.h2>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Hero Content */}
        <AnimatePresence>
          {!isTransformed && (
            <motion.div
              className="space-y-8 lg:w-1/2 lg:pt-20"
              variants={slideUp(0.5 * 1.2)}
              initial="hidden"
              animate="show"
              exit="hidden"
              transition={{ duration: 0.6 }}
            >
              <h1
                className={`text-5xl md:text-6xl font-extrabold ${
                  isLightMode ? "text-gray-900" : "text-white"
                }`}
              >
                Turning Ideas Into
                <span
                  className={`text-customTeal-600 block`}
                  style={{
                    textShadow: "2px 2px 4px black",
                  }}
                >
                  Elegant Digital Experiences
                </span>
              </h1>
              <p
                className={`text-lg ${
                  isLightMode ? "text-gray-700" : "text-gray-300"
                }`}
              >
                We specialize in creating innovative solutions across web
                development, design, and 3D animation, all while delivering
                exceptional user experiences.
              </p>
              <div className="flex flex-col sm:flex-row">
                <button
                  onClick={handleGetStarted}
                  className={`inline-flex items-center justify-center px-8 py-3 text-base font-medium rounded-md ${
                    isLightMode
                      ? "text-white bg-customTeal-600 hover:bg-customTeal-700"
                      : "text-white bg-customTeal-600 hover:bg-customTeal-700"
                  } transition-transform duration-500 hover:scale-105`}
                >
                  Get Started
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {/* Cards Section */}
      <div className="relative w-full h-screen lg:h-screen flex items-center justify-center">
        <AnimatePresence>
          {isTransformed && (
            <motion.div
              className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 px-2 w-full h-full justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1, delay: 0.6 }}
            >
              {/* Web & Mobile Development Card */}
              <Link to="/webdev" className="cursor-pointer lg:h-72 h-36 m-4">
                <motion.div
                  className={`p-2 sm:p-4 rounded-lg shadow-md hover:shadow-xl transition-all duration-700 ease-in-out transform hover:-translate-y-2 hover:scale-105 lg:h-72 h-36 w-full ${
                    isLightMode
                      ? "bg-gradient-to-r from-yellow-400 to-yellow-300"
                      : "bg-gradient-to-r from-yellow-700 to-yellow-500"
                  }`}
                  custom={0}
                  variants={cardContainerVariants}
                >
                  <Code
                    className={`h-8 sm:h-10 w-8 sm:w-10 ${
                      isLightMode ? "text-gray-900" : "text-gray-200"
                    } mb-1 sm:mb-2`}
                  />
                  <motion.h3
                    variants={textVariants}
                    className={`text-base sm:text-xl font-semibold ${
                      isLightMode ? "text-gray-900" : "text-gray-200"
                    } mb-1 sm:mb-2`}
                  >
                    Web Development
                  </motion.h3>
                  <motion.p
                    variants={textVariants}
                    className={`${
                      isLightMode ? "text-gray-900" : "text-gray-200"
                    } text-sm sm:text-sm`}
                  >
                    Crafting dynamic, responsive websites and innovative mobile
                    apps tailored to your business needs using cutting-edge
                    technologies.
                  </motion.p>
                </motion.div>
              </Link>
   {/* 3D Animation Card */}
              <Link to="/animation" className="cursor-pointer lg:h-72 h-36 m-4">
                <motion.div
                  className={`p-2 sm:p-4 rounded-lg shadow-md hover:shadow-xl transition-all duration-700 ease-in-out transform hover:-translate-y-2 hover:scale-105 lg:h-72 h-36 w-full ${
                    isLightMode
                      ? "bg-gradient-to-r from-purple-400 to-purple-300"
                      : "bg-gradient-to-r from-purple-700 to-purple-500"
                  }`}
                  custom={2}
                  variants={cardContainerVariants}
                >
                  <Box
                    className={`h-8 sm:h-10 w-8 sm:w-10 ${
                      isLightMode ? "text-gray-900" : "text-gray-200"
                    } mb-1 sm:mb-2`}
                  />
                  <motion.h3
                    variants={textVariants}
                    className={`text-base sm:text-xl font-semibold ${
                      isLightMode ? "text-gray-900" : "text-gray-200"
                    } mb-1 sm:mb-2`}
                  >
                    3D Animation
                  </motion.h3>
                  <motion.p
                    variants={textVariants}
                    className={`${
                      isLightMode ? "text-gray-900" : "text-gray-200"
                    } text-sm sm:text-sm`}
                  >
                    Transforming concepts into stunning visuals with
                    high-quality 3D modeling and animation for all industries.
                  </motion.p>
                </motion.div>
              </Link>
              {/* Design & Branding Card */}
              <Link to="/graphics" className="cursor-pointer lg:h-72 h-36 m-4">
                <motion.div
                  className={`p-2 sm:p-4 rounded-lg shadow-md hover:shadow-xl transition-all duration-700 ease-in-out transform hover:-translate-y-2 hover:scale-105 lg:h-72 h-36 w-full ${
                    isLightMode
                      ? "bg-gradient-to-r from-blue-400 to-blue-300"
                      : "bg-gradient-to-r from-blue-700 to-blue-500"
                  }`}
                  custom={1}
                  variants={cardContainerVariants}
                >
                  <Palette
                    className={`}h-8 sm:h-10 w-8 sm:w-10 ${
                      isLightMode ? "text-gray-900" : "text-gray-200"
                    } mb-1 sm:mb-2`}
                  />
                  <motion.h3
                    variants={textVariants}
                    className={`text-base sm:text-xl font-semibold ${
                      isLightMode ? "text-gray-900" : "text-gray-200"
                    } mb-1 sm:mb-2`}
                  >
                    Design & Video Editing
                  </motion.h3>
                  <motion.p
                    variants={textVariants}
                    className={`${
                      isLightMode ? "text-gray-900" : "text-gray-200"
                    } text-sm sm:text-sm`}
                  >
                    Creating visually compelling brand identities that resonate
                    with your audience and create an outstanding videos that leave a lasting impression.
                  </motion.p>
                </motion.div>
              </Link>

           
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}
