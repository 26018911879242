import React from "react";
import { motion } from "framer-motion";
import { User, Flag, Globe } from "lucide-react";
import { useDarkMode } from "../Context/DarkModeContext"; // Import DarkModeContext

const aboutData = [
  {
    icon: Flag,
    title: "Our Mission",
    content:
      "To empower businesses with cutting-edge digital solutions that drive growth and innovation.",
  },
  {
    icon: Globe,
    title: "Our Values",
    content:
      "Integrity, innovation, and excellence are the pillars that guide our journey.",
  },
  {
    icon: User,
    title: "Our Team",
    content:
      "A diverse group of experts dedicated to turning your ideas into reality.",
  },
];

const About = () => {
  const { isLightMode } = useDarkMode(); // Access light/dark mode state

  return (
    <div
      className={`${
        isLightMode
          ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
          : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
      }`}
    >
      <section className="min-h-screen flex flex-col items-center justify-center py-24 px-4 text-customTeal-600">
        <motion.h1
          className="text-7xl font-bold text-center mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.8 }}
        >
          About Us
        </motion.h1>

        <motion.p
          className={`text-2xl text-center max-w-4xl mb-16 ${
            isLightMode ? "text-gray-600" : "text-gray-400"
          }`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          We are a passionate team creating innovative digital solutions.
        </motion.p>

        <div className="flex flex-col md:flex-row items-center justify-center gap-8">
          {aboutData.map((item, index) => (
            <motion.div
              key={index}
              className={`w-full max-w-xs rounded-xl shadow-xl p-8 text-center transform transition-transform duration-300 hover:scale-105 ${
                isLightMode
                  ? "bg-gradient-to-br from-white via-customTeal-100 to-white"
                  : "bg-gradient-to-br from-customTeal-600 via-customTeal-800 to-customTeal-600"
              }`}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ delay: 0.1 * index, duration: 0.8 }}
            >
              <item.icon
                className={`h-16 w-16 mb-6 mx-auto ${
                  isLightMode ? "text-customTeal-500" : "text-customTeal-200"
                }`}
              />
              <h3
                className={`text-3xl font-semibold mb-4 ${
                  isLightMode ? "text-gray-800" : "text-white"
                }`}
              >
                {item.title}
              </h3>
              <p
                className={`${isLightMode ? "text-gray-600" : "text-gray-400"}`}
              >
                {item.content}
              </p>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default About;
