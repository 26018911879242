import { useEffect, useState } from "react";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Animation from "./pages/Animation";
import GraphicsDesign from "./pages/Graphics";
import WebDevelopment from "./pages/Web_dev";
import { AnimatePresence } from "framer-motion";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Loader from "./components/Loader";

function App() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 2000);
    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  return (
    <Router>
      <AnimatePresence>{loaded ? null : <Loader />}</AnimatePresence>

      {loaded && <Navbar />}

      {loaded && (
        <>
          {/* <Navbar /> */}
          <div className="min-h-screen">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/webdev" element={<WebDevelopment />} />
              <Route path="/graphics" element={<GraphicsDesign />} />
              <Route path="/animation" element={<Animation />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer />
          </div>
        </>
      )}
    </Router>
  );
}

export default App;
