import React, { useState } from "react";
import {
  Code,
  Database,
  Layout,
  Smartphone,
  Globe,
  Lock,
  Zap,
  GitBranch,
  Mail,
  Github,
  Linkedin,
} from "lucide-react";
import CoverVideo from "../components/CoverVideo";
import MainVideo from "../assets/WebBgdWithLael.mp4";
import { motion } from "framer-motion";
import {
  FaReact,
  FaVuejs,
  FaNodeJs,
  FaPython,
  FaAws,
  FaJava,
  FaDocker,
} from "react-icons/fa";
import {
  SiNextdotjs,
  SiNuxtdotjs,
  SiTailwindcss,
  SiBootstrap,
  SiMongodb,
  SiPostgresql,
  SiFirebase,
  SiKubernetes,
  SiMaterialdesign,
  SiAntdesign,
  SiMysql,
  SiGooglecloud,
} from "react-icons/si";
import { FaGolang } from "react-icons/fa6";
import { DiRedis, DiDjango } from "react-icons/di";
import { GiRocket } from "react-icons/gi";
import sabi from "../assets/Sabi.jpg";
import teseme from "../assets/teseme.jpg";
import chez from "../assets/chez.jpg";
import Logos from "../assets/logos_black_trim.png";
import LogosWhite from "../assets/logos_white_trim.png";
import { Link } from "react-router-dom";
import { useDarkMode } from "../Context/DarkModeContext";

const technologies = [
  {
    name: "Frontend",
    tools: [
      { label: "React", icon: FaReact },
      { label: "Vue.js", icon: FaVuejs },
      { label: "Next.js", icon: SiNextdotjs },
      { label: "Nuxt.js", icon: SiNuxtdotjs },
      { label: "Bootstrap", icon: SiBootstrap },
      { label: "Tailwind CSS", icon: SiTailwindcss },
      { label: "Material UI", icon: SiMaterialdesign },
      { label: "Ant Design", icon: SiAntdesign },
    ],
  },
  {
    name: "Backend",
    tools: [
      { label: "Node.js", icon: FaNodeJs },
      { label: "Python", icon: FaPython },
      { label: "Java", icon: FaJava },
      { label: "Go", icon: FaGolang },
      { label: "Django", icon: DiDjango },
    ],
  },
  {
    name: "Database",
    tools: [
      { label: "PostgreSQL", icon: SiPostgresql },
      { label: "MongoDB", icon: SiMongodb },
      { label: "Redis", icon: DiRedis },
      { label: "Firebase", icon: SiFirebase },
      { label: "MySQL", icon: SiMysql },
    ],
  },
  {
    name: "DevOps",
    tools: [
      { label: "Docker", icon: FaDocker },
      { label: "AWS", icon: FaAws },
      { label: "CI/CD", icon: GiRocket },
      { label: "Kubernetes", icon: SiKubernetes },
      { label: "GCP", icon: SiGooglecloud },
    ],
  },
];

const portfolio = [
  {
    title: "Sabi Works Market-Place",
    image: sabi,
    category: "Website Development",
    url: "https://www.sabi-jobs.com/",
  },
  {
    title: "Import Company Landing Page",
    image: teseme,
    category: "Website Development",
    url: "https://teseme-main.vercel.app/",
  },
  {
    title: "Hotel Menu and food management with QR Code",
    image: chez,
    category: "Website Development",
    url: "https://chezjeanpierrefrenchpatisserie.com/",
  },
];

const services = [
  {
    icon: Globe,
    title: "Web Applications Development",
    description:
      "High-performance web apps built for scalability, seamless user experience, and responsive design.",
  },

  {
    icon: Smartphone,
    title: "Mobile Application Development",
    description:
      "Cross-platform mobile apps with responsive, user-friendly designs, optimized for both iOS and Android.",
  },

  {
    icon: Database,
    title: "Database Management",
    description:
      "Efficient database solutions for reliable data storage, seamless access, and optimized performance.",
  },
  {
    icon: Lock,
    title: "Security & Compliance",
    description:
      "Comprehensive security solutions to safeguard your data and ensure compliance with industry standards.",
  },
];

const processes = [
  {
    icon: Layout,
    title: "Planning & Architecture",
    description: "Comprehensive system design and project roadmap.",
  },
  {
    icon: Code,
    title: "Development",
    description: "Clean, efficient code with best practices.",
  },
  {
    icon: GitBranch,
    title: "Version Control",
    description: "Streamlined code management with Git and CI/CD.",
  },
  {
    icon: Zap,
    title: "Performance Optimization",
    description: "Ensuring fast and reliable web applications.",
  },
];

export default function DeveloperPortfolio() {
  const { isLightMode } = useDarkMode();
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };

  return (
    <div
      style={{ "--primary-color": "#FFD700", overflowX: "hidden" }}
      className="text-white"
    >
      {/* Cover Video Section */}
   <div
        className={`relative ${isVideoLoading ? 'bg-black' : ''}`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        {isVideoLoading && (
          <div className="absolute inset-0 flex items-center justify-center z-10">

            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[var(--primary-color)]"></div>
          </div>
        )}
        <CoverVideo vidSource={MainVideo} onLoadedData={handleVideoLoad} />
      </div>
      {/* Hero Section */}
      <div
        className={`relative py-24 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
      >
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          <div className="relative z-10">
            <h1
              className={`text-5xl font-bold mb-6 text-[var(--primary-color)]`}
            >
              Software & Web Development
            </h1>
            <p
              className={`text-xl max-w-2xl mb-8 ${
                isLightMode ? "text-gray-800" : "text-white"
              }`}
            >
              Creating stunning visual experiences that elevate your brand and
              engage your audience
            </p>
            <div className="flex space-x-4">
              <Link
                to="/contact"
                className={`${
                  isLightMode
                    ? "bg-[var(--primary-color)] text-white hover:bg-yellow-500"
                    : "bg-white text-[var(--primary-color)] hover:bg-yellow-500"
                } px-8 py-3 rounded-md transition-colors`}
              >
                Start Project
              </Link>
              <a
                href="#portfolio"
                className={`${
                  isLightMode
                    ? "border-2 border-black text-black hover:bg-black/10"
                    : "border-2 border-white text-white hover:bg-white/10"
                } px-8 py-3 rounded-md transition-colors`}
              >
                View Portfolio
              </a>
            </div>
          </div>
        </motion.div>
        <div
          className={`absolute inset-0 ${
            isLightMode ? "bg-white/20" : "bg-black/20"
          }`}
        ></div>
      </div>

      {/* Services Section */}
      <section
        id="services"
        className={`py-20 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <motion.div
          className="max-w-7xl mx-auto px-6"
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <motion.h2
            className="text-4xl font-bold text-center mb-12"
            style={{
              color: "var(--primary-color)",
            }}
          >
            Services Offered
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={index}
                className={`p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow hover:animate-pulse ${
                  isLightMode
                    ? "bg-white text-black border border-gray-300"
                    : "bg-gray-800 text-white border border-gray-700"
                }`}
                whileHover={{ y: -10 }}
              >
                <service.icon
                  className={`h-12 w-12 mb-4 ${
                    isLightMode
                      ? "text-[var(--primary-color)]"
                      : "text-[var(--primary-color)]"
                  }`}
                />
                <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
                <p>{service.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>

      {/* Technologies Section */}
      <section
        className={`py-20 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <motion.div
          className="max-w-7xl mx-auto px-6"
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <motion.h2
            className="text-4xl font-bold text-center mb-12"
            style={{
              color: "var(--primary-color)",
            }}
          >
            Technologies We Use
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {technologies.map((tech, index) => (
              <div
                key={index}
                className={`p-6 rounded-2xl ${
                  isLightMode
                    ? "bg-white text-black border border-gray-300"
                    : "bg-gray-800 text-white border border-gray-700"
                }`}
              >
                <h3 className="text-2xl text-center font-bold mb-4 text-yellow-500">
                  {tech.name}
                </h3>
                <div className="flex flex-wrap gap-2">
                  {tech.tools.map((tool, idx) => (
                    <motion.div
                      key={idx}
                      className="group relative px-3 py-1 rounded-full flex items-center justify-center"
                      whileHover={{ scale: 1.25 }}
                    >
                      <tool.icon className="h-8 w-8 text-current" />
                      <motion.span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full opacity-0 group-hover:opacity-100 group-hover:-translate-y-6 bg-yellow-500 text-white text-xs font-semibold px-2 py-1 rounded shadow-lg transition-all duration-300 ease-in-out">
                        {tool.label}
                      </motion.span>
                    </motion.div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </motion.div>
      </section>

      {/* Development Process Section */}
      <section
        className={`py-20 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <motion.div
          className="max-w-7xl mx-auto"
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <motion.h2
            className="text-4xl font-bold text-center mb-12"
            style={{ color: "var(--primary-color)" }}
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Development Process
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {processes.map((process, index) => (
              <div
                key={index}
                className={`p-8 rounded-2xl ${
                  isLightMode
                    ? "bg-white text-black border border-gray-300"
                    : "bg-gray-800 text-white border border-gray-700"
                }`}
              >
                <process.icon
                  className={`h-12 w-12 text-[var(--primary-color)] mb-4`}
                />
                <h3
                  className={`text-2xl font-bold ${
                    isLightMode ? "text-black" : "text-white"
                  }`}
                  style={{
                    textShadow: isLightMode ? "none" : "2px 2px 4px black",
                  }}
                >
                  {process.title}
                </h3>
                <p
                  className={`${
                    isLightMode ? "text-gray-800" : "text-gray-400"
                  }`}
                >
                  {process.description}
                </p>
              </div>
            ))}
          </div>
        </motion.div>
      </section>

      {/* Portfolio Section */}
      <section
        id="portfolio"
        className={`py-20 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2
            className="text-5xl font-bold text-center mb-16"
            style={{
              color: isLightMode ? "var(--primary-color)" : "white",
            }}
          >
            Featured Projects
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {portfolio.map((item, index) => {
              const animationProps = {
                initial: {
                  opacity: 0,
                  x: index === 1 ? 0 : index < 1 ? -100 : 100,
                },
                whileInView: { opacity: 1, x: 0 },
                transition: { duration: 2 },
              };

              return (
                <motion.div
                  key={index}
                  className="group relative overflow-hidden rounded-2xl shadow-lg"
                  {...animationProps}
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-80 object-cover transform group-hover:scale-110 transition-transform duration-300"
                  />
                  <div
                    className={`absolute inset-0 bg-gradient-to-t ${
                      isLightMode
                        ? "from-white/80 to-transparent"
                        : "from-black/80 to-transparent"
                    } opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
                  >
                    <div
                      className={`absolute bottom-0 left-0 right-0 p-6 ${
                        isLightMode ? "text-black" : "text-white"
                      }`}
                    >
                      <h3
                        className={`text-2xl font-bold mb-2 ${
                          isLightMode ? "text-black" : "text-white"
                        }`}
                      >
                        {item.title}
                      </h3>
                      <p
                        className={`text-gray-700 ${
                          isLightMode ? "text-gray-800" : "text-gray-200"
                        }`}
                      >
                        {item.category}
                      </p>
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Clients List */}
      <div
        className="client-section grid grid-cols-3 gap-4"
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <div
          className={`p-1 flex items-center w-screen lg:h-32 justify-center ${
            isLightMode
              ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
              : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
          }`}
        >
          <img
            src={`${isLightMode ? `${LogosWhite}` : `${Logos}`}`}
            alt={`Collaborated Clients List`}
            className="h-32 w-max"
          />
        </div>
      </div>

      {/* Contact Section */}
      <footer
        className={`py-12 text-center ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <div className="flex justify-center space-x-6 mb-6 relative">
          {/* Mail Icon */}
          <motion.a
            href="mailto:yabhabline@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="group relative"
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Mail
              className={`h-8 w-8 ${
                isLightMode ? "text-gray-800" : "text-gray-500"
              } hover:${
                isLightMode ? "text-yellow-500" : "text-yellow-400"
              } hover:scale-110 duration-300`}
            />
            <span
              className={`absolute bottom-10 left-1/2 -translate-x-1/2 ${
                isLightMode
                  ? "bg-yellow-500 text-white"
                  : "bg-yellow-400 text-black"
              } text-xs rounded-md py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity`}
            >
              Mail
            </span>
          </motion.a>

          {/* GitHub Icon */}
          <motion.a
            href="https://github.com/Yeabsra-Habtu"
            target="_blank"
            rel="noopener noreferrer"
            className="group relative"
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Github
              className={`h-8 w-8 ${
                isLightMode ? "text-gray-800" : "text-gray-500"
              } hover:${
                isLightMode ? "text-yellow-500" : "text-yellow-400"
              } hover:scale-110 duration-300`}
            />
            <span
              className={`absolute bottom-10 left-1/2 -translate-x-1/2 ${
                isLightMode
                  ? "bg-yellow-500 text-white"
                  : "bg-yellow-400 text-black"
              } text-xs rounded-md py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity`}
            >
              GitHub
            </span>
          </motion.a>

          {/* LinkedIn Icon */}
          <motion.a
            href="https://www.linkedin.com/in/yeabsra-habtu-aligned"
            target="_blank"
            rel="noopener noreferrer"
            className="group relative"
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Linkedin
              className={`h-8 w-8 ${
                isLightMode ? "text-gray-800" : "text-gray-500"
              } hover:${
                isLightMode ? "text-yellow-500" : "text-yellow-400"
              } hover:scale-110 duration-300`}
            />
            <span
              className={`absolute bottom-10 left-1/2 -translate-x-1/2 ${
                isLightMode
                  ? "bg-yellow-500 text-white"
                  : "bg-yellow-400 text-black"
              } text-xs rounded-md py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity`}
            >
              LinkedIn
            </span>
          </motion.a>
        </div>
      </footer>
    </div>
  );
}
