import React, { useState } from "react";
import { useDarkMode } from "../Context/DarkModeContext"; // Import the dark mode context
import {
  Box,
  Camera,
  Film,
  Cuboid,
  Play,
  Tv,
  Clapperboard,
} from "lucide-react";
import CoverVideo from "../components/CoverVideo";
import MainVideo from "../assets/ReelImproved.mp4";
import BarImage from "../assets/CharactersBar.jpg";
import BarImageWhite from "../assets/characters_white.jpg";
import newbg from "../assets/newbg.jpg";
import AnimationImage from "../assets/Animation.jpg";
import Concept from "../assets/Concept.jpg";
import Product from "../assets/Product.jpg";
import Sculpts from "../assets/ModelingSculpts.jpg";
import Beauty from "../assets/beauty_product.jpg";
import Medieval from "../assets/medieval_village.jpg";
import Watch from "../assets/watch.jpg";
import Logos from "../assets/logos_black_trim.png";
import LogosWhite from "../assets/logos_white_trim.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const services = [
  {
    icon: Cuboid,
    title: "3D Modeling",
    description: "Detailed 3D models for any purpose",
  },
  {
    icon: Camera,
    title: "Product Visualization",
    description: "Photorealistic product renders",
  },
  {
    icon: Film,
    title: "Character Animation",
    description: "Engaging character animations",
  },
  { icon: Tv, title: "Motion Graphics", description: "Dynamic motion design" },
];

const portfolio = [
  {
    title: "Watch Product",
    image: Watch,
    category:
      "An ad crafted for the renowned Ishac Pejman watch, designed with meticulous attention to detail and uncompromising quality from concept through to final production.",
  },
  {
    title: "Beauty Product",
    image: Beauty,
    category:
      "A sophisticated beauty product crafted for Oju Beauty, with every detail infused with style and elegance.",
  },
  {
    title: "Medieval Village",
    image: Medieval,
    category:
      "A stunning medieval fantasy village, intricately designed for an immersive short animation.",
  },
];

const workflow = [
  {
    icon: Box,
    title: "Concept",
    description: "Initial concept development and storyboarding",
    image: Concept,
  },
  {
    icon: Cuboid,
    title: "Blockout/Animatic",
    description: "3D modeling and asset creation",
    image: Sculpts,
  },
  {
    icon: Play,
    title: "Feedback",
    description: "Bringing objects to life with motion",
    image: AnimationImage,
  },
  {
    icon: Clapperboard,
    title: "Final Product",
    description: "Final rendering and post-production",
    image: Product,
  },
];

export default function Animation() {
  const { isLightMode } = useDarkMode(); // Get light mode state
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };

  const themeStyles = {
    primaryColor: isLightMode ? "#6b46c1" : "#805ad5",
    backgroundColor: isLightMode ? "#ffffff" : "#000000",
    textColor: isLightMode ? "#000000" : "#ffffff",
    mutedTextColor: isLightMode ? "#555555" : "#cccccc",
    cardBackground: isLightMode ? "#f9f9f9" : "#1a1a1a",
    cardShadow: isLightMode
      ? "shadow-md hover:shadow-lg"
      : "shadow-lg hover:shadow-xl",
  };

  return (
    <div
      style={{
        "--primary-color": themeStyles.primaryColor,
        backgroundColor: themeStyles.backgroundColor,
        color: themeStyles.textColor,
        overflowX: "hidden", // Prevent horizontal scrolling
      }}
      className={isLightMode ? "light-mode" : "dark-mode"}
    >
      {/* Hero Section */}
      <div
        className={`relative ${isVideoLoading ? 'bg-black' : ''}`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        {isVideoLoading && (
          <div className="absolute inset-0 flex items-center justify-center z-10">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[var(--primary-color)]"></div>
          </div>
        )}
        <CoverVideo vidSource={MainVideo} onLoadedData={handleVideoLoad} />
      </div>

      <div
        className="relative bg-cover bg-center py-24 text-left "
        style={{
          backgroundImage: `${
            isLightMode ? `url(${BarImageWhite})` : `url(${BarImage})`
          }`,
          overflowX: "hidden",
        }}
      >
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          <div className="relative z-10 text-white">
            <h1 className="text-5xl text-[var(--primary-color)] font-bold mb-6">
              3D & Animation
            </h1>
            <p className="text-xl max-w-2xl mb-8 items-start">
              Bringing ideas to life through stunning 3D visualization and
              dynamic animation.
            </p>
            <div className="flex space-x-4">
              <Link
                to="/contact"
                className={`px-8 py-3 rounded-md transition-colors ${
                  isLightMode
                    ? "bg-[var(--primary-color)] text-white hover:bg-purple-600"
                    : "bg-white text-[var(--primary-color)] hover:bg-gray-200"
                }`}
              >
                Start Project
              </Link>
              <a
                href="#portfolio"
                className={`px-8 py-3 border-2 rounded-md transition-colors ${
                  isLightMode
                    ? "border-gray-700 text-gray-400 hover:bg-gray-200 hover:text-black"
                    : "border-white text-white hover:bg-white/10"
                }`}
              >
                View Portfolio
              </a>
            </div>
          </div>
        </motion.div>
        <div className="absolute inset-0 bg-black/20"></div>
      </div>
      {/* Services Section */}
      <section
        className="py-20 bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${newbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflowX: "hidden",
        }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <motion.h2
              initial={{ scale: 0.8, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1 }}
              className="text-5xl font-bold text-center mb-12"
              style={{
                color: "var(--primary-color)",
                textShadow: "2px 2px 4px black",
              }}
            >
              Animation Services
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {services.map((service, index) => (
                <div
                  key={index}
                  className={`p-8 rounded-2xl transition-shadow ${themeStyles.cardShadow}`}
                  style={{ backgroundColor: themeStyles.cardBackground }}
                >
                  {React.createElement(service.icon, {
                    className: `h-12 w-12 mb-6 text-[var(--primary-color)]`,
                  })}
                  <h3 className="text-xl font-bold mb-4">{service.title}</h3>
                  <p
                    className="text-gray-600"
                    style={{ color: themeStyles.mutedTextColor }}
                  >
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
      {/* Portfolio Section */}
      <section
        id="portfolio"
        className={`py-20 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
            className="text-5xl font-bold text-center mb-16"
            style={{ color: "var(--primary-color)" }}
          >
            Featured Projects
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {portfolio.map((item, index) => {
              const animationProps = {
                initial: {
                  opacity: 0,
                  x: index === 1 ? 0 : index < 1 ? -100 : 100,
                },
                whileInView: { opacity: 1, x: 0 },
                transition: { duration: 2 },
              };

              return (
                <motion.div
                  key={index}
                  className="group relative overflow-hidden rounded-2xl shadow-lg"
                  {...animationProps}
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-80 object-cover transform group-hover:scale-110 transition-transform duration-300"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
                      <h3 className="text-2xl font-bold mb-2">{item.title}</h3>
                      <p>{item.category}</p>
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </section>
      {/* Workflow Section */}
      <section
        className={`py-20 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          <h2
            className="text-5xl font-bold text-center mb-16"
            style={{ color: "var(--primary-color)" }}
          >
            Our Workflow
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {workflow.map((step, index) => (
              <div key={index} className="relative">
                {/* Workflow Card */}
                <div
                  className={`p-8 rounded-xl transition-shadow ${themeStyles.cardShadow}`}
                  style={{
                    backgroundColor: themeStyles.cardBackground,
                    color: themeStyles.textColor,
                  }}
                >
                  {/* Icon */}
                  {React.createElement(step.icon, {
                    className: `h-12 w-12 mb-6 text-[var(--primary-color)]`,
                  })}
                  {/* Title */}
                  <h3 className="text-xl font-bold mb-4">{step.title}</h3>
                  {/* Description */}
                  <p
                    className="text-gray-600"
                    style={{ color: themeStyles.mutedTextColor }}
                  >
                    {step.description}
                  </p>
                </div>

                {/* Connector Line for Larger Screens */}
                {index < workflow.length - 1 && (
                  <div
                    className="hidden lg:block absolute top-1/2 -right-4 w-8 h-0.5"
                    style={{ backgroundColor: themeStyles.mutedTextColor }}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </motion.div>
      </section>
      {/* Clients List */}
      <div
        className="client-section grid grid-cols-3 gap-4"
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <div
          className={`p-1 flex items-center w-screen lg:h-32 justify-center ${
            isLightMode
              ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
              : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
          }`}
        >
          <img
            src={`${isLightMode ? `${LogosWhite}` : `${Logos}`}`}
            alt={`Collaborated Clients List`}
            className="h-32 w-max"
          />
        </div>
      </div>
    </div>
  );
}
