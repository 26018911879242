import React, { createContext, useContext, useState } from "react";

const DarkModeContext = createContext();

export const useDarkMode = () => useContext(DarkModeContext);

export const DarkModeProvider = ({ children }) => {
  const [isLightMode, setIsLightMode] = useState(() => {
    // Check localStorage for saved mode
    const savedMode = localStorage.getItem("isLightMode");
    return savedMode !== null ? JSON.parse(savedMode) : true; // Default to true if not set
  });

  const toggleLightMode = () => {
    setIsLightMode((prev) => {
      const newMode = !prev;
      localStorage.setItem("isLightMode", JSON.stringify(newMode)); // Save new mode to localStorage
      return newMode;
    });
  };

  return (
    <DarkModeContext.Provider value={{ isLightMode, toggleLightMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};
