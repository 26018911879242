import React, { useState } from "react";
import {
  PenTool,
  Image,
  Layers,
  Monitor,
  Package,
  Target,
  Lightbulb,
} from "lucide-react";
import CoverVideo from "../components/CoverVideo";
import MainVideo from "../assets/VidReelGraphics.mp4";
import Logos from "../assets/logos_black_trim.png";
import LogosWhite from "../assets/logos_white_trim.png";
import dream from "../assets/Dream_Homes.png";
import cube from "../assets/cube.png";
import slash from "../assets/Slash_Films.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useDarkMode } from "../Context/DarkModeContext";

// Define services, portfolio items, and process steps as before
const services = [
  {
    icon: PenTool,
    title: "Logo Design",
    description: "Memorable brand marks that capture your essence",
  },
  {
    icon: Monitor,
    title: "Infomercial",
    description: "Engaging video content that captivates your audience",
  },
  {
    icon: Package,
    title: "Brand Identity",
    description: "Complete visual language for your brand",
  },
  {
    icon: Image,
    title: "Marketing Materials",
    description: "Engaging visuals for all platforms",
  },
];

const portfolio = [
  {
    title: "Dream Homes",
    image: dream,
    category:
      "A sleek and modern logo designed to evoke a sense of luxury and comfort, perfectly representing the ideal dream home experience.",
  },
  {
    title: "Slash Films",
    image: slash,
    category:
      "A dynamic and edgy logo created for a film production company, combining bold typography and creative elements to convey storytelling excellence.",
  },
  {
    title: "Cube Packaging",
    image: cube,
    category:
      "An innovative and minimalist logo crafted for a packaging company, showcasing versatility and sustainability with a clean, cube-inspired design.",
  },
];

const process = [
  {
    icon: Lightbulb,
    title: "Discovery",
    description: "Understanding your brand, goals, and target audience",
  },
  {
    icon: Target,
    title: "Strategy",
    description: "Developing a focused creative direction",
  },
  {
    icon: PenTool,
    title: "Design",
    description: "Creating compelling visual solutions",
  },
  {
    icon: Layers,
    title: "Refinement",
    description: "Perfecting every detail for maximum impact",
  },
];

export default function GraphicsDesign() {
  const { isLightMode } = useDarkMode();
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };

  return (
    <div
      style={{
        "--primary-color": isLightMode ? "#007BFF" : "#1e90ff", // Blue for both modes
        "--secondary-color": isLightMode ? "#000000" : "#FFFFFF", // Text color based on mode
        overflowX: "hidden",
      }}
      className={isLightMode ? "bg-white text-black" : "bg-black text-white"}
    >
    {/* Hero Section */}
      <div
        className={`relative ${isVideoLoading ? 'bg-black' : ''}`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        {isVideoLoading && (
          <div className="absolute inset-0 flex items-center justify-center z-10">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[var(--primary-color)]"></div>
          </div>
        )}
        <CoverVideo vidSource={MainVideo} onLoadedData={handleVideoLoad} />
      </div>
      <div
        className={`relative py-24 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
      >
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          <div className="relative z-10">
            <h1 className="text-5xl text-[var(--primary-color)] font-bold mb-6">
              Graphics & Design
            </h1>
            <p className="text-xl max-w-2xl mb-8">
              Creating stunning visual experiences that elevate your brand and
              engage your audience
            </p>
            <div className="flex space-x-4">
              <Link
                to="/contact"
                className={`px-8 py-3 rounded-md transition-colors ${
                  isLightMode
                    ? "bg-[var(--primary-color)] text-white hover:bg-blue-700"
                    : "bg-white text-[var(--primary-color)] hover:bg-blue-700"
                }`}
              >
                Start Project
              </Link>
              <a
                href="#portfolio"
                className={`border-2 px-8 py-3 rounded-md transition-colors ${
                  isLightMode
                    ? "border-black hover:bg-gray-200"
                    : "border-white hover:bg-white/10"
                }`}
              >
                View Portfolio
              </a>
            </div>
          </div>
        </motion.div>
        <div
          className={`absolute inset-0 ${
            isLightMode ? "bg-white/20" : "bg-black/20"
          }`}
        ></div>
      </div>
      {/* Services Section */}
      <section
        className={`py-20 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
        style={{ overflowX: "hidden" }}
      >
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          <div className="text-center mb-16">
            <h2
              className="text-4xl font-bold mb-4"
              style={{ color: "var(--primary-color)" }}
            >
              Our Design Services
            </h2>
            <p
              className={`text-xl ${
                isLightMode ? "text-gray-700" : "text-gray-600"
              }`}
            >
              Comprehensive design solutions for every need
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className={`p-8 rounded-2xl hover:shadow-xl transition-shadow ${
                  isLightMode ? "bg-white" : "bg-gray-800"
                }`}
              >
                <service.icon
                  className={`h-12 w-12 mb-6 ${
                    isLightMode
                      ? "text-[var(--primary-color)]"
                      : "text-[var(--primary-color)]"
                  }`}
                />
                <h3 className="text-xl font-bold mb-4">{service.title}</h3>
                <p className={isLightMode ? "text-gray-700" : "text-gray-600"}>
                  {service.description}
                </p>
              </div>
            ))}
          </div>
        </motion.div>
      </section>
      {/* Portfolio Section */}
      <section
        id="portfolio"
        className={`py-20 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <motion.div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            className="text-5xl font-bold text-center mb-16"
            style={{ color: "var(--primary-color)" }}
          >
            Featured Projects
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {portfolio.map((item, index) => {
              const animationProps = {
                initial: {
                  opacity: 0,
                  x: index === 1 ? 0 : index < 1 ? -100 : 100,
                },
                whileInView: { opacity: 1, x: 0 },
                transition: { duration: 2 },
              };

              return (
                <motion.div
                  key={index}
                  className={`group relative overflow-hidden rounded-2xl shadow-lg ${
                    isLightMode ? "bg-white" : ""
                  }`}
                  {...animationProps}
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-80 object-cover transform group-hover:scale-110 transition-transform duration-300"
                  />
                  <div
                    className={`absolute inset-0 bg-gradient-to-t ${
                      isLightMode
                        ? "from-white/80 to-transparent"
                        : "from-black/80 to-transparent"
                    } opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
                  >
                    <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
                      <h3 className="text-2xl font-bold mb-2">{item.title}</h3>
                      <p className="text-gray-200">{item.category}</p>
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </motion.div>
      </section>
      {/* Process Section */}
      <section
        className={`py-20 ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          <h2
            className="text-4xl font-bold text-center mb-16"
            style={{ color: "var(--primary-color)" }}
          >
            Our Design Process
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {process.map((step, index) => (
              <div key={index} className="relative">
                <div
                  className={`p-8 rounded-2xl  ${
                    isLightMode
                      ? "bg-white border border-gray-500"
                      : "bg-gray-800"
                  }`}
                >
                  <step.icon
                    className={`h-12 w-12 ${
                      isLightMode
                        ? "text-[var(--primary-color)]"
                        : "text-[var(--primary-color)]"
                    } mb-6`}
                  />
                  <h3 className="text-xl font-bold mb-4">{step.title}</h3>
                  <p
                    className={`${
                      isLightMode ? "text-gray-700" : "text-gray-600"
                    }`}
                  >
                    {step.description}
                  </p>
                </div>
                {index < process.length - 1 && (
                  <div
                    className={`hidden lg:block absolute top-1/2 -right-4 w-8 h-0.5 ${
                      isLightMode ? "bg-gray-300" : "bg-gray-300"
                    }`}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </motion.div>
      </section>
      {/* Clients List */}
      <div
        className="client-section grid grid-cols-3 gap-4"
        style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
      >
        <div
          className={`p-1 flex items-center w-screen lg:h-32 justify-center ${
            isLightMode
              ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
              : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
          }`}
        >
          <img
            src={`${isLightMode ? `${LogosWhite}` : `${Logos}`}`}
            alt={`Collaborated Clients List`}
            className="h-32 w-max"
          />
        </div>
      </div>
    </div>
  );
}
