import React, { useState } from "react";
import { motion } from "framer-motion";
import { Mail, Phone } from "lucide-react";
import emailjs from "@emailjs/browser";
import { useDarkMode } from "../Context/DarkModeContext";

const Contact = () => {
  // State to handle form data
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    message: "",
  });
  const { isLightMode } = useDarkMode();

  // State to manage submission status
  const [isSending, setIsSending] = useState(false);

  // Update form data when user types
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    // Send email using EmailJS
    emailjs
      .send(
        "service_3z0606m", // Replace with your Service ID
        "template_5dw1kao", // Replace with your Template ID
        formData,
        "4bTjGMK7ulpsXeZdo" // Replace with your Public Key
      )
      .then(
        (response) => {
          alert("Message sent successfully!");
          setFormData({ name: "", contact: "", message: "" });
          setIsSending(false);
        },
        (error) => {
          console.error("Email sending error:", error);
          alert("Failed to send the message. Please try again.");
          setIsSending(false);
        }
      );
  };

  return (
    <section
      className={`min-h-screen flex flex-col items-center justify-center ${
        isLightMode
          ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
          : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
      } p-4 font-wittgenstein pb-24 md:pb-32`}
    >
      <motion.h2
        className="text-5xl font-extrabold mb-12 mt-44 text-customTeal-600"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Contact Us
      </motion.h2>

      <motion.form
        onSubmit={handleSubmit}
        className={`bg-gradient-to-br ${
          isLightMode
            ? "from-white via-customTeal-100 to-white"
            : "bg-gradient-to-br from-black via-customTeal-900 to-customTeal-800 text-white"
        } rounded-2xl shadow-lg p-8 w-full max-w-lg mx-auto`}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="mb-6">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your Name"
            className={`w-full px-4 py-3 border border-customTeal-600 rounded-md focus:border-customTeal-600 focus:ring-customTeal-600 ${
              isLightMode
                ? "bg-gradient-to-br from-white via-customTeal-100 to-white text-gray-900"
                : "bg-gradient-to-br from-black via-customTeal-900 to-customTeal-800 text-white"
            }`}
            required
          />
        </div>
        <div className="mb-6">
          <input
            type="email"
            name="contact"
            value={formData.contact}
            onChange={handleChange}
            placeholder="Your Email"
            className={`w-full px-4 py-3 border  border-customTeal-600 rounded-md focus:border-customTeal-600 focus:ring-customTeal-600 ${
              isLightMode
                ? "bg-gradient-to-br from-white via-customTeal-100 to-white text-gray-900 focus:border-customTeal-600"
                : "bg-gradient-to-br from-black via-customTeal-900 to-customTeal-800 text-white"
            }`}
            required
          />
        </div>
        <div className="mb-6">
          <textarea
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            placeholder="Your Message"
            className={`w-full px-4 py-3 border border-customTeal-600 rounded-md focus:border-customTeal-600 focus:ring-customTeal-600 ${
              isLightMode
                ? "bg-gradient-to-br from-white via-customTeal-100 to-white text-gray-900"
                : "bg-gradient-to-br from-black via-customTeal-900 to-customTeal-800 text-white"
            }`}
            required
          ></textarea>
        </div>
        <motion.button
          type="submit"
          className={`bg-customTeal-600 ${
            isLightMode ? "text-black" : "text-white"
          }  py-3 px-6 rounded-lg hover:bg-customTeal-700 transition-colors`}
          whileHover={{ scale: 1.05 }}
          disabled={isSending}
        >
          {isSending ? "Sending..." : "Send Message"}
        </motion.button>
      </motion.form>

      {/* Contact Info Section */}
      <div className="mt-16 space-y-8 flex flex-col items-center">
        <motion.div
          className="flex items-center space-x-4"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <Mail className="text-customTeal-600 hover:scale-110 duration-300" />
          <p className="text-lg hover:scale-110 duration-300">
            info@laelstudios.com
          </p>
        </motion.div>

        <motion.div
          className="flex items-center space-x-4"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <Phone className="text-customTeal-600 hover:scale-110 duration-300" />
          <a
            className="text-lg hover:scale-110 duration-300"
            href="tel:+251912378022"
          >
            +251912378022
          </a>
        </motion.div>
        <motion.div
          className="flex items-center space-x-4"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <Phone className="text-customTeal-600 hover:scale-110 duration-300" />
          <a
            className="text-lg hover:scale-110 duration-300"
            href="tel:+251935348244"
          >
            +251935348244
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
