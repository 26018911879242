import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import FilledAnimatedLogo from "./FilledAnimatedLogo";
import { slideUp } from "./CoverVideo";
import { useDarkMode } from "../Context/DarkModeContext";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const { isLightMode, toggleLightMode } = useDarkMode();
  const handleToggle = () => setIsOpen(!isOpen);

  // Colors for logo and links
  const logoColor = isLightMode ? "text-customTeal-600" : "text-customTeal-600"; // Light mode text black for logo
  const linksColor = isLightMode
    ? "text-transparent bg-clip-text bg-gradient-to-r from-customTeal-600 via-black to-customTeal-600"
    : "text-transparent bg-clip-text bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300"; // Light mode text dark for links

  const linkHoverColor = isLightMode
    ? "hover:text-teal-600"
    : "hover:text-teal-600"; // Changed hover color to teal-600 for dark mode

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-20 bg-transparent ${
        isLightMode
          ? "text-transparent bg-clip-text bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300"
          : "text-transparent bg-clip-text bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800"
      } font-wittgenstein overflow-visible`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-start gap-28 lg:justify-between h-24">
          <div className="flex-shrink-0 flex items-center">
            <motion.div
              variants={slideUp(0.5)}
              initial="hidden"
              whileInView="show"
            >
              <Link
                to="/"
                className={`text-6xl font-bold ${logoColor}`}
                onClick={() => {
                  if (window.location.pathname === "/") {
                    window.location.reload();
                  }
                }}
              >
                <motion.div whileHover={{ scale: 1.1 }}>
                  <FilledAnimatedLogo />
                </motion.div>
              </Link>
            </motion.div>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-6 font-roboto">
            {["Home", "Categories", "About", "Contact"].map((item, index) => (
              <motion.div
                key={item}
                variants={slideUp(0.5 + index * 0.1)}
                initial="hidden"
                whileInView="show"
                className={`${linkHoverColor}`}
                whileHover={{ scale: 1.1 }}
              >
                {item === "Categories" ? (
                  <div
                    className="relative group"
                    onMouseEnter={() => setIsDropdownOpen(true)}
                    onMouseLeave={() => setIsDropdownOpen(false)}
                  >
                    <span
                      className={`${linksColor} ${linkHoverColor} px-4 py-3 rounded-md text-lg font-medium cursor-pointer`}
                    >
                      {item}
                    </span>
                    {/* Dropdown Menu for Desktop */}
                    <AnimatePresence>
                      {isDropdownOpen && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.3 }}
                          className={`absolute left-0 mt-2 w-56 shadow-xl rounded-lg overflow-hidden z-20 border font-wittgenstein ${
                            isLightMode
                              ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
                              : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
                          }`}
                        >
                          <Link
                            to="/animation"
                            className={`block px-6 py-3 transition duration-300 ease-in-out rounded-md text-center ${
                              isLightMode
                                ? "text-gray-800 hover:bg-gradient-to-r from-indigo-100 to-indigo-500 hover:text-indigo-800"
                                : "text-gray-200 hover:bg-gradient-to-r from-indigo-500 to-purple-500 hover:text-white"
                            }`}
                          >
                            Animation
                          </Link>
                          <Link
                            to="/webdev"
                            className={`block px-6 py-3 transition duration-300 ease-in-out rounded-md text-center ${
                              isLightMode
                                ? "text-gray-800 hover:bg-gradient-to-r from-yellow-100 to-yellow-500 hover:text-yellow-800"
                                : "text-gray-200 hover:bg-gradient-to-r from-yellow-400 to-yellow-600 hover:text-white"
                            }`}
                          >
                            Web Development
                          </Link>
                          <Link
                            to="/graphics"
                            className={`block px-6 py-3 transition duration-300 ease-in-out rounded-md text-center ${
                              isLightMode
                                ? "text-gray-800 hover:bg-gradient-to-r from-blue-100 to-blue-500 hover:text-blue-800"
                                : "text-gray-200 hover:bg-gradient-to-r from-blue-400 to-blue-600 hover:text-white"
                            }`}
                          >
                            Graphics
                          </Link>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                  <Link
                    to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                    className={`${linksColor} ${linkHoverColor} px-4 py-3 rounded-md text-lg font-medium`}
                  >
                    {item}
                  </Link>
                )}
              </motion.div>
            ))}
          </div>

          <div className="flex relative justify-between items-center cursor-pointer">
            <motion.button
              onClick={toggleLightMode}
              className={`${
                isLightMode
                  ? "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
                  : "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
              } w-12 h-6 flex items-center rounded-full p-1 cursor-pointer`}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <motion.div
                className={`w-5 h-5 rounded-full bg-white dark:bg-customTeal-600 shadow-md ${
                  isLightMode ? "ml-5" : "ml-1 "
                }`}
                layout
                transition={{
                  type: "spring",
                  stiffness: 700,
                  damping: 30,
                }}
              />
            </motion.button>
          </div>

          {/* Mobile Menu Button */}
          <motion.div
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 300 }}
            className="flex lg:hidden items-center pr-6 hover:scale-110 duration-300"
          >
            <motion.button
              layout
              transition={{
                type: "spring",
                stiffness: 700,
                damping: 30,
              }}
              onClick={handleToggle}
              className={`${
                isLightMode ? "text-gray-900" : "text-gray-200"
              } focus:outline-none`}
              aria-label="Mobile menu"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </motion.button>
          </motion.div>
        </div>
      </div>

      {/* Mobile Menu */}
      <motion.div
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: { opacity: 1, height: "auto" },
          closed: { opacity: 0, height: 0 },
        }}
        className={`md:hidden shadow-xl overflow-hidden ${
          isLightMode
            ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
            : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
        }`}
      >
        <div className="px-4 pt-4 pb-6 space-y-4">
          {["Home", "About", "Categories", "Contact"].map((item) => (
            <div key={item}>
              <motion.div>
                {item === "Categories" ? (
                  <>
                    <span
                      className={`block px-4 py-3 rounded-md text-lg font-medium cursor-pointer transition duration-300 ${
                        isLightMode
                          ? "text-gray-800 hover:bg-customTeal-400 hover:text-gray-900"
                          : "text-gray-300 hover:bg-customTeal-700 hover:text-white"
                      }`}
                      onClick={() =>
                        setIsMobileDropdownOpen(!isMobileDropdownOpen)
                      }
                    >
                      {item}
                    </span>
                    {/* Mobile Dropdown */}
                    {isMobileDropdownOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                        className="pl-4"
                      >
                        <Link
                          to="/animation"
                          className={`block px-4 py-2 rounded-md transition duration-300 ${
                            isLightMode
                              ? "text-gray-800 hover:bg-customTeal-400 hover:text-gray-900"
                              : "text-gray-300 hover:bg-customTeal-700 hover:text-white"
                          }`}
                          onClick={() => setIsOpen(false)}
                        >
                          Animation
                        </Link>
                        <Link
                          to="/webdev"
                          className={`block px-4 py-2 rounded-md transition duration-300 ${
                            isLightMode
                              ? "text-gray-800 hover:bg-gray-300 hover:text-gray-900"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white"
                          }`}
                          onClick={() => setIsOpen(false)}
                        >
                          Web Development
                        </Link>
                        <Link
                          to="/graphics"
                          className={`block px-4 py-2 rounded-md transition duration-300 ${
                            isLightMode
                              ? "text-gray-800 hover:bg-gray-300 hover:text-gray-900"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white"
                          }`}
                          onClick={() => setIsOpen(false)}
                        >
                          Graphics
                        </Link>
                      </motion.div>
                    )}
                  </>
                ) : (
                  <Link
                    to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                    className={`block px-4 py-3 rounded-md text-lg font-medium transition duration-300 ${
                      isLightMode
                        ? "text-gray-800 hover:bg-gray-200 hover:text-gray-900"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white"
                    }`}
                    onClick={() => setIsOpen(false)}
                  >
                    {item}
                  </Link>
                )}
              </motion.div>
            </div>
          ))}
        </div>
      </motion.div>
    </nav>
  );
};

export default Navbar;
