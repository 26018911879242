import React from "react";
import { motion } from "framer-motion";

const pathVariant = {
  hidden: { opacity: 0, pathLength: 0 },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { duration: 2, ease: "easeInOut" },
  },
};

const fillVariant = {
  hidden: { fillOpacity: 0 },
  visible: {
    fillOpacity: 1,
    transition: { delay: 2, duration: 1 },
  },
};

const Loader = () => {
  return (
    <motion.div
      className="absolute inset-0 flex flex-col justify-center items-center bg-gradient-to-r from-customTeal-900 via-customTeal-500 to-customTeal-900 text-customTeal-900 z-10 overflow-hidden"
      initial={{
        y: 0,
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 2,
      }}
    >
      <motion.svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        className="w-32 h-auto"
        initial="hidden"
        animate="visible"
      >
        <motion.polygon
          className="stroke-current"
          points="500 500 0 500 22.45 455.1 44.9 410.19 67.36 365.29 250 0 276.77 53.54 183.78 240.29 121.28 365.29 98.83 410.19 76.38 455.1 423.62 455.1 401.17 410.19 153.08 410.19 175.44 365.29 432.64 365.29 455.1 410.19 477.55 455.1 500 500"
          fill="none"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="6"
          variants={pathVariant}
        />
        <motion.path
          className="stroke-current"
          d="M303.88,107.36l-106.32,213.03h212.64l-106.32-213.03ZM303.88,207.95l33.7,67.52h-67.4l33.7-67.52Z"
          fill="none"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="6"
          variants={pathVariant}
        />
        <motion.polygon
          points="500 500 0 500 22.45 455.1 44.9 410.19 67.36 365.29 250 0 276.77 53.54 183.78 240.29 121.28 365.29 98.83 410.19 76.38 455.1 423.62 455.1 401.17 410.19 153.08 410.19 175.44 365.29 432.64 365.29 455.1 410.19 477.55 455.1 500 500"
          fill="currentColor"
          stroke="none"
          variants={fillVariant}
        />
        <motion.path
          d="M303.88,107.36l-106.32,213.03h212.64l-106.32-213.03ZM303.88,207.95l33.7,67.52h-67.4l33.7-67.52Z"
          fill="currentColor"
          stroke="none"
          variants={fillVariant}
        />
      </motion.svg>
      {/* <motion.div
        className="mt-6 text-3xl font-bold tracking-wider text-center"
        variants={textVariant}
        animate="visible"
        whileHover="visible"
        initial="hidden"
      >
        <motion.span className="font-kaushan text-6xl">Lael</motion.span>
      </motion.div> */}
    </motion.div>
  );
};

export default Loader;
