import React from "react";
import { Code, Palette, Box, CheckCircle } from "lucide-react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useDarkMode } from "../Context/DarkModeContext"; // Import the dark mode context

const services = [
  {
    icon: Code,
    title: "Web Development",
    description: "Web apps built with cutting-edge technologies",
    features: [
      "Full-stack Development",
      "Responsive Design",
      "E-commerce Solutions",
      "API Integration",
      "Custom Web Applications",
      "SEO Optimization",
      "Progressive Web Apps (PWAs)",
      "Cloud Hosting and Deployment",
      "Third-party Service Integration",
    ],
    link: "/webdev",
    gradientFrom: "from-yellow-700",
    gradientTo: "to-yellow-500",
    lightGradientFrom: "from-yellow-300",
    lightGradientTo: "to-yellow-100",
  },
  {
    icon: Palette,
    title: "Design & Branding",
    description: "Creative designs that capture your brand's essence",
    features: [
      "UI/UX Design",
      "Brand Identity",
      "Logo Design",
      "Marketing Materials",
      "Packaging Design",
      "Website Mockups",
      "Social Media Templates",
      "Typography and Iconography",
      "Creative Concept Development",
    ],
    link: "/graphics",
    gradientFrom: "from-blue-700",
    gradientTo: "to-blue-500",
    lightGradientFrom: "from-blue-300",
    lightGradientTo: "to-blue-100",
  },
  {
    icon: Box,
    title: "3D Animation",
    description: "Stunning visual experiences that engage and inspire",
    features: [
      "3D Modeling",
      "Character Animation",
      "Motion Graphics",
      "Visual Effects",
      "Product Visualization",
      "Virtual Reality (VR) Content",
      "Augmented Reality (AR) Content",
      "Simulation Videos",
      "Architectural Rendering",
    ],
    link: "/animation",
    gradientFrom: "from-purple-700",
    gradientTo: "to-purple-500",
    lightGradientFrom: "from-purple-300",
    lightGradientTo: "to-purple-100",
  },
];

export default function Services() {
  const { isLightMode } = useDarkMode(); // Access the light mode state

  return (
    <section
      id="services"
      className={`py-24 ${
        isLightMode
          ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
          : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-20">
          <h2
            className={`text-6xl font-bold text-customTeal-600 mb-4`}
            style={{
              textShadow: "2px 2px 4px black",
            }}
          >
            Our Services
          </h2>
          <p
            className={`text-xl ${
              isLightMode ? "text-gray-600" : "text-gray-400"
            } max-w-3xl mx-auto`}
          >
            We offer comprehensive digital solutions to help your business
            thrive in the modern world.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }} // Delay for staggered effect
            >
              <div className="w-full max-w-sm p-4 sm:p-8 ">
                <div
                  className={`p-6 rounded-lg ${
                    isLightMode
                      ? `bg-gradient-to-r ${service.lightGradientFrom} ${service.lightGradientTo}`
                      : `bg-gradient-to-r ${service.gradientFrom} ${service.gradientTo}`
                  }`}
                >
                  <h5
                    className={`mb-4 text-2xl font-medium text-customTeal-600`}
                    style={{
                      textShadow: "1px 1px 2px black",
                    }}
                  >
                    {service.title}
                  </h5>
                  <div
                    className={`flex items-baseline ${
                      isLightMode ? "text-gray-900" : "text-white"
                    }`}
                  >
                    <span style={{ width: "4rem", height: "4rem" }}>
                      {React.createElement(service.icon, {
                        style: { width: "100%", height: "100%" },
                      })}
                    </span>
                  </div>
                  <ul className="space-y-5 my-7">
                    {service.features.map((feature, idx) => (
                      <li className="flex" key={idx}>
                        <CheckCircle
                          className={`flex-shrink-0 w-6 h-6 ${
                            isLightMode
                              ? "text-customTeal-500"
                              : "text-customTeal-500"
                          }`}
                          aria-hidden="true"
                        />
                        <span
                          className={`text-base font-normal leading-tight text-gray-800 ms-3`}
                        >
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to={`${service.link}`}
                    className={`text-white bg-customTeal-700 hover:bg-customTeal-800 focus:ring-2 focus:outline-none focus:ring-customTeal-200 ${
                      isLightMode
                        ? "dark:bg-customTeal-600 dark:hover:bg-customTeal-700 dark:focus:ring-customTeal-900"
                        : ""
                    } font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center`}
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
