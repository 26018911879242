import React, { useState } from "react";
import { motion } from "framer-motion";
import { useDarkMode } from "../Context/DarkModeContext"; // Import DarkModeContext
// Images
import sabi from "../assets/Sabi.jpg";
import teseme from "../assets/teseme.jpg";
import chez from "../assets/chez.jpg";
import Beauty from "../assets/beauty_product.jpg";
import Medieval from "../assets/medieval_village.jpg";
import Watch from "../assets/watch.jpg";
import dream from "../assets/Dream_Homes.png";
import cube from "../assets/cube.png";
import slash from "../assets/Slash_Films.png";

const projects = {
  web: [
    {
      title: "Work Market Place",
      image: sabi,
      category: "Web Development",
      url: "https://www.sabi-jobs.com/",
      description:
        "An innovative marketplace designed to seamlessly connect employers and talent, fostering a dynamic environment where opportunities and skills meet.",
    },
    {
      title: "Hotel Menu and Food Management Dashboard",
      image: chez,
      category: "Web Development",
      url: "https://chezjeanpierrefrenchpatisserie.com/",
      description:
        "A secure and intuitive platform for managing hotel menus, orders, and inventory, enhancing operational efficiency and guest satisfaction.",
    },
    {
      title: "Import Export Company Profile and Landing Page",
      image: teseme,
      category: "Web Development",
      url: "https://teseme-main.vercel.app/",
      description:
        "A professional and engaging landing page designed to showcase the import-export company's services, streamline client inquiries, and boost global visibility.",
    },
  ],
  design: [
    {
      title: "Dream Homes",
      image: dream,
      category: "Design",
      description:
        "A sleek and modern logo designed to evoke a sense of luxury and comfort, perfectly representing the ideal dream home experience.",
    },
    {
      title: "Slash Films",
      image: slash,
      category: "Design",
      description:
        "A dynamic and edgy logo created for a film production company, combining bold typography and creative elements to convey storytelling excellence.",
    },
    {
      title: "Cube Packaging",
      image: cube,
      category: "Design",
      description:
        "An innovative and minimalist logo crafted for a packaging company, showcasing versatility and sustainability with a clean, cube-inspired design.",
    },
  ],
  animation: [
    {
      title: "Watch Product",
      image: Watch,
      description:
        "An ad crafted for the renowned Ishac Pejman watch, designed with meticulous attention to detail and uncompromising quality from concept through to final production.",
      category: "3D & Animation",
    },
    {
      title: "Beauty Product",
      image: Beauty,
      description:
        "A stunning medieval fantasy village, intricately designed for an immersive short animation.",
      category: "3D & Animation",
    },
    {
      title: "Medieval Village",
      image: Medieval,
      description:
        "A sophisticated beauty product crafted for Oju Beauty, with every detail infused with style and elegance.",
      category: "3D & Animation",
    },
  ],
};

export default function Portfolio() {
  const { isLightMode } = useDarkMode(); // Access light mode state
  const [activeTab, setActiveTab] = useState("web");

  const tabs = [
    { id: "web", label: "Web Development" },
    { id: "design", label: "Design" },
    { id: "animation", label: "3D & Animation" },
  ];

  return (
    <section
      className={`py-24 min-h-screen ${
        isLightMode
          ? "bg-gradient-to-r from-customTeal-300 via-white to-customTeal-300 text-gray-900"
          : "bg-gradient-to-r from-customTeal-800 via-black to-customTeal-800 text-white"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2
            className={`text-6xl font-bold mb-4 text-customTeal-600`}
            style={{
              textShadow: "2px 2px 4px black",
            }}
          >
            Our Work
          </h2>
          <p
            className={`text-xl ${
              isLightMode ? "text-gray-600" : "text-gray-400"
            }`}
          >
            Explore our latest projects and creative solutions
          </p>
        </div>

        <div className="flex justify-center mb-12">
          <div
            className={`inline-flex rounded-lg border p-1 ${
              isLightMode ? "border-gray-300" : "border-gray-200"
            }`}
          >
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-6 py-2 rounded-md text-sm font-medium transition-colors ${
                  activeTab === tab.id
                    ? "bg-customTeal-600 text-white"
                    : isLightMode
                    ? "text-gray-600 hover:text-customTeal-600"
                    : "text-gray-200 hover:text-customTeal-400"
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projects[activeTab] &&
            projects[activeTab].map((project, index) => (
              <motion.a
                key={index}
                href={project.url}
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`group relative overflow-hidden rounded-2xl shadow-lg ${
                  isLightMode ? "hover:shadow-xl" : "hover:shadow-2xl"
                } transition-all duration-300`}
              >
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-80 object-cover transform group-hover:scale-110 transition-transform duration-300"
                />
                <div
                  className={`absolute inset-0 bg-gradient-to-t from-black/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
                >
                  <div className="absolute bottom-0 left-0 right-0 p-6">
                    <h3 className="text-2xl font-bold mb-2">{project.title}</h3>
                    <p className="text-sm mb-2">{project.category}</p>
                    <p>{project.description}</p>
                  </div>
                </div>
              </motion.a>
            ))}
        </div>
      </div>
    </section>
  );
}
